// Core
import React, { useEffect, useState, useRef, useCallback } from "react"
import debounce from "lodash.debounce"

// InView detector
import { InView } from "react-intersection-observer"

import { isMobile } from "react-device-detect"

// Components
import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitHeading from "./../split-big-heading/split-big-heading"

// Styles
import styles from "./our-companies.module.scss"

//SVG

const OurCompaniesCompany = props => {
  const { name, image, description } = props

  return (
    <div className={styles.itemSingleCompany}>
      <div className={styles.itemSingleCompanyLogo}>
        <img src={image.fluid.src} alt={name} />
      </div>

      {description && <p>{description}</p>}
    </div>
  )
}

const OurCompaniesNew = props => {
  const { data } = props

  const title = data.sectionTitle,
    companies = data.companies

  return (
    <ScrollSection>
      <section className={`container ${styles.companies}`}>
        <InView triggerOnce={true} threshold={0.2}>
          {({ inView, ref, entry }) => (
            <div
              className="cols flex flex--center"
              ref={ref}
              inview={inView.toString()}
            >
              <div className={`col m12 ${styles.heading}`} data-scroll>
                <SplitHeading classString={`h2 ${styles.h2}`}>
                  {title}
                </SplitHeading>
              </div>

              <div className={`col m12`} data-scroll>
                <div className={`${styles.items}`}>
                  {companies.map(
                    ({ title, icon, description, companies }, index) => (
                      <div key={index} className={`${styles.item}`}>
                        <div className={`${styles.itemHead}`}>
                          <img src={icon.fluid.src} alt={title} />
                          <p>{description}</p>
                        </div>
                        <ul className={styles.itemCompanies}>
                          {companies &&
                            companies.map((item, index) => (
                              <li key={index} data-category={item.cat}>
                                {item.companyWebsite ? (
                                  <a
                                    target="_blank"
                                    href={item.companyWebsite}
                                    aria-label={`${item.name}'s website`}
                                  >
                                    <OurCompaniesCompany
                                      name={item.name}
                                      image={item.companyImage}
                                      description={item.subtitle}
                                    />
                                  </a>
                                ) : (
                                  <OurCompaniesCompany
                                    name={item.name}
                                    image={item.companyImage}
                                    description={item.subtitle}
                                  />
                                )}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </InView>
      </section>
    </ScrollSection>
  )
}

export default OurCompaniesNew
